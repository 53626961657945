// COLORS
$ERROR_RED: #FF6E6E !default;
$GREEN_COLOR: #3FD184 !default;
$MAIN_COLOR: #308CF6 !default;
$DARKER_MAIN_COLOR: #0F5EF6 !default;
$MAIN_LIGHT_COLOR: #11BEFE !default;
$MAIN_DARK_COLOR: #474747 !default;
$FOOTER_BACKGROUND_COLOR: #2F90F9 !default;
$GRAY_COLOR: #C2C2C2 !default;
$CHART_START_COLOR: #622AAE !default;
$PALLETE_PRIMARY: #FFFFFF;

:export {
  chart_start_color: $CHART_START_COLOR;
  main_color: $MAIN_COLOR;
  palletePrimary: $PALLETE_PRIMARY;
}